#main-svg {
    width: 100%;
    height: 100vh;
    border: 1px solid grey;
}

#main-svg:hover {
    cursor: grab;
}

#main-svg:active {
    cursor: grabbing;
}
